import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import { IoIosArrowForward } from "react-icons/io";
import CallNumCom from "../Components/CallNumCom";
import "../Styles/Com_Style.css";
import "../index.css";

// Image URLs

const Image2 = "https://www.glelectronics.net/imgs/ComImg/wires.webp";
const Image3 = "https://www.glelectronics.net/imgs/ComImg/IMG_3761.webp";
const Image4 = "https://www.glelectronics.net/imgs/ComImg/IMG_3755.webp";

function Commercial() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  
  return (
    <>
      <Helmet>
  <title>Commercial Security and Monitoring - GL Electronics</title>
  <link rel="canonical" href="https://www.glelectronics.net/voiceoverip-security-and-communication-systems" />

  {/* Primary Meta Description */}
  <meta
    name="description"
    content="GL Electronics offers comprehensive commercial security solutions, including CCTV installation, Voice Over IP, access control, and alarm systems!"
  />

  {/* Open Graph for Social Sharing */}
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Commercial Security and Monitoring - GL Electronics" />
  <meta
    property="og:description"
    content="GL Electronics offers comprehensive commercial security solutions, including CCTV installation, Voice Over IP, access control, and alarm systems!"
  />
  <meta property="og:image" content="https://www.glelectronics.net/imgs/ComImg/IMG_3761.webp" />
  <meta property="og:image:width" content="800" />
  <meta property="og:image:height" content="600" />
  <meta property="og:url" content="https://www.glelectronics.net/voiceoverip-security-and-communication-systems" />

  {/* Schema Markup */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Commercial Security and Monitoring - GL Electronics",
        "description": "GL Electronics offers comprehensive commercial security solutions, including CCTV installation, network security, access control, and alarm systems, tailored for businesses in Arizona.",
        "url": "https://www.glelectronics.net/voiceoverip-security-and-communication-systems",
        "image": {
          "@type": "ImageObject",
          "url": "https://www.glelectronics.net/imgs/ComImg/IMG_3761.webp",
          "width": 800,
          "height": 600
        },
        "publisher": {
          "@type": "Organization",
          "name": "GL Electronics",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.glelectronics.net/imgs/GL-electronics-logo.png",
            "width": 192,
            "height": 192
          }
        },
        "mainEntity": {
          "@type": "Service",
          "name": "Commercial Security Solutions",
          "description": "Advanced security solutions for businesses, including CCTV installation, network security, access control, and alarm systems.",
          "areaServed": [
            { "@type": "Place", "name": "Mesa" },
            { "@type": "Place", "name": "Phoenix" },
            { "@type": "Place", "name": "Tempe" },
            { "@type": "Place", "name": "Scottsdale" },
            { "@type": "Place", "name": "Chandler" },
            { "@type": "Place", "name": "Gilbert" },
            { "@type": "Place", "name": "Apache Junction" },
            { "@type": "Place", "name": "Glendale" }
          ]
        }
      }
    `}
  </script>
</Helmet>

      {/* Header */}
      <header className="headerCS">
      <img
        src="https://www.glelectronics.net/imgs/ComImg/Business_pic.webp"
        alt="house interior for GL Electronics"
        className="HeaderImageCS"
        width="1920"
        height="500"
      />
        <div className="headingStyleCS">
          <h1 className="headerTitleCS">Commercial Monitoring and Security Solutions</h1>
        </div>
      </header>


      {/* Introduction */}
      <section>
        <div className="TotalContC">
          <main className="TextContainerC">
            <div className="infoBoxC">
              <h2>Empowering Your Commercial Security</h2>
              <p>
                Enhance the safety and protection of your business with our comprehensive <strong>security solutions</strong>. Our advanced security systems are designed to meet the complex needs of commercial environments, including:
                <br /> 
                <br /> 
                 <strong>CCTV installation</strong> for continuous monitoring.
                <br />
                <strong>Voice Over IP</strong> systems.
                <br />
                <strong>Intercom Systems</strong>.
                and More:         
                <br />
                <ul style={{ listStyle: 'none' }}>
                  <li>Structured Wiring</li>
                  <li>Live Monitoring</li>
                  <li>Alarm Systems</li>
                  <li>Audio / Video</li>
                </ul>
                <CallNumCom />
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Surveillance</h2>
              <p>
                Monitor and secure your premises with advanced <strong>CCTV surveillance systems</strong> tailored to your business needs. Our <strong>security camera systems</strong> provide high-definition video and reliable performance to keep your business safe.
                <br />
                <br />
                Want to know more about our camera options? Check out the <strong>cameras</strong> we use by visiting <a href="https://www.cctvcore.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>www.cctvcore.com</a>.
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Alarm Systems</h2>
              <p>
                Stay vigilant and respond swiftly to any security breaches with our state-of-the-art <strong>alarm systems</strong>. 
                <br/>
                We offer the whole package: cameras, motion detection, License plate recognition, and more!
                <br/>
                Our <strong>advanced security</strong> solutions are designed to protect your business around the clock.
                <br />
                <br />
                <strong>Ask about our 24/7 Monitoring!</strong>
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Access Control</h2>
              <p>
                Regulate entry and restrict unauthorized access to sensitive areas of your business premises with our <strong>access control solutions</strong>. Ensure your business's security with cutting-edge access management technologies.
                <br/>
                <br/>
                We also install: 
                <br/>
                <strong> VOIP: Voice Over IP</strong>
                <br/>
                <strong>Intercom Systems</strong>
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Network Security Solutions</h2>
              <p>
                Protect your digital assets with our comprehensive <strong>network security solutions</strong>. We provide secure and efficient networking for businesses, ensuring your devices stay communicating with each other.
              </p>
            </div>
          </main>

          <aside className="IMGcontainerC">
            <div>
              <LazyLoad>
                <div className="IMGcardVC" style={{ '--background-image': `url(${Image2})` }} alt="Commercial structured wiring setup for security camera systems"></div>
              </LazyLoad>
              <LazyLoad>
                <div className="IMGcardVCM" style={{ '--background-image': `url(${Image3})` }} alt="Commercial network security setup with CCTV cameras"></div>
              </LazyLoad>
            </div>
            <div>
              <LazyLoad>
                <div className="IMGcardVC" style={{ '--background-image': `url(${Image4})` }} alt="Business CCTV camera installation in Arizona"></div>
              </LazyLoad>
            </div>
          </aside>
        </div>
      </section>

      {/* Contact Link */}
      <div>
        <Link className="CButtonC" to="../Contact" style={{ top: 150 }}>
          LET'S SECURE YOUR BUSINESS.
          CONTACT US!
          <IoIosArrowForward style={{ color: 'white' }} size={35} />
        </Link>
      </div>
    </>
  );
}

export default Commercial;
