import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, ValidationError } from '@formspree/react';
import "../Styles/ServiceRequest.css";

function ServiceRequest() {
    const [state, handleSubmit] = useForm("movqzjqr"); 
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
        propertyType: '',
        gateCode: '',
        requestType: '',
        serviceType: '',
        serviceDetails: '',
        howDidYouHear: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(e);
    };

    return (
        <div className="service-request-page">
            <Helmet>
                <title>Service Request - GL Electronics</title>
                <link rel="canonical" href="https://www.glelectronics.net/service-request" />
                <meta
                    name="description"
                    content="Request a service call for alarm systems, cameras, or any other security electronics from our security solution experts at GL electronics located in Arizona."
                />
            </Helmet>

            <div className="service-form-wrapper">
                <h1 className="form-title">Service Request Form</h1>
                <div className="form-container">
                    <p className="form-description">
                        The purpose of this form is so that we may help with resolving 
                        <br/>
                        any issues you are facing with 
                        <br/>
                        your alarm systems, cameras, network, or any other system 
                        <br/>
                        <br/>
                        Please fill out the form below to help us serve you better.</p>
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <h2>Contact Information *</h2>
                            <label className="form-label">First Name *</label>
                            <input
                                className="form-input"
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Last Name *</label>
                            <input
                                className="form-input"
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Company Name (if applicable)</label>
                            <input
                                className="form-input"
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Email *</label>
                            <input
                                className="form-input"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Phone Number *</label>
                            <input
                                className="form-input"
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-section">
                            <h2 className="section-title">Address *</h2>
                            <div className="form-group">
                                <label className="form-label">Street 1 *</label>
                                <input
                                    className="form-input"
                                    type="text"
                                    name="street1"
                                    value={formData.street1}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Street 2</label>
                                <input
                                    className="form-input"
                                    type="text"
                                    name="street2"
                                    value={formData.street2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="address-row">
                                <input
                                    className="form-input"
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="City *"
                                    required
                                />
                                <input
                                    className="form-input"
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    placeholder="State *"
                                    required
                                />
                                <input
                                    className="form-input"
                                    type="text"
                                    name="zipCode"
                                    value={formData.zipCode}
                                    onChange={handleChange}
                                    placeholder="ZIP Code *"
                                    required
                                />
                            </div>
                            
                        </div>

                        <div className="form-section">  
                            <h2>Property Information *</h2>  
                            <div className="form-group">
                                <label className="form-label">Property Type *</label>
                                <select
                                    className="form-input"
                                    name="propertyType"
                                    value={formData.propertyType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select...</option>
                                    <option value="Residential">Residential</option>
                                    <option value="Commercial">Commercial</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                                <div className="form-group">
                                    <label className="form-label">Gate Code (if applicable)</label>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="gateCode"
                                        value={formData.gateCode}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Request Type *</label>
                                    <select
                                        className="form-input"
                                        name="requestType"
                                        value={formData.requestType}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select...</option>
                                        <option value="Installation">Installation</option>
                                        <option value="Repair">Repair</option>
                                        <option value="Maintenance">Maintenance</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {/* <div className="form-group">
                                    <label className="form-label">Service Type</label>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="serviceType"
                                        value={formData.serviceType}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label className="form-label">Service Details</label>
                                    <textarea
                                        className="form-input"
                                        name="serviceDetails"
                                        value={formData.serviceDetails}
                                        onChange={handleChange}
                                        rows="4"
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label className="form-label"></label>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="howDidYouHear"
                                        value={formData.howDidYouHear}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                 <div className="form-group">
                                <label className="form-label">How Did You Hear About Us? *</label>
                                <select
                                    className="form-input"
                                    name="howDidYouHear"
                                    value={formData.howDidYouHear}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select...</option>
                                    <option value="Google">Google</option>
                                    <option value="Social">Facebook / Instagram</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            </div>
                            <button type="submit" className="submit-btn" disabled={state.submitting}>
                                Submit Request
                            </button>
                            <ValidationError prefix="Form" field="form" errors={state.errors} />
                        </form>
                    {state.succeeded && <p className="success-message">Thank you for your request!</p>}
                </div>
            </div>
        </div>
    );
}

export default ServiceRequest;