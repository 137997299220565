//Home.js

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import Slideshow from "../Components/SlideShow";
import { IoIosArrowForward } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { TbDeviceCctv } from "react-icons/tb";
import { RiAlarmWarningLine } from "react-icons/ri";
import { MdOndemandVideo } from "react-icons/md";
import { IoIosGitNetwork } from "react-icons/io";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { IoKeypadSharp } from "react-icons/io5";


import "../Styles/SlideShowStyle.css";
import '../Styles/Home_style.css';
import "../index.css";



const Image2 = "https://www.glelectronics.net/imgs/CloseC.webp";
const Image3 = "https://www.glelectronics.net/imgs/IMG_3659.webp";
const Image4 = "https://www.glelectronics.net/imgs/WhiteC.webp";

function Home() {
  return (
    <>
      {/* Set Meta Description */}
      <Helmet>
      <title>GL Electronics - Advanced Security Solutions: CCTV, ALARMS</title>
      <link rel="canonical" href="https://www.glelectronics.net/" />
        {/* Primary Meta Description for SEO */}
        <meta
          name="description"
          content="Install advanced security solutions and systems. CCTV surveillance, alarms, network and more. Homes and Businesses Phoenix, Scottsdale, Chandler, and more"
        />

        {/* Open Graph for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GL Electronics - Advanced Security Solutions: CCTV, ALARMS" />
        <meta
          property="og:description"
          content="Installation of advanced security systems, CCTV surveillance, alarms, network, and more solutions. Homes and Businesses Phoenix, Scottsdale, Chandler, and more."
        />
        <meta
          property="og:image"
          content="https://www.glelectronics.net/imgs/GLelectronics-logo.jpg"
        />
        <meta property="og:image:width" content="192" />
        <meta property="og:image:height" content="192" />
        <meta property="og:url" content="https://www.glelectronics.net/" />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "GL Electronics",
              "url": "https://www.glelectronics.net/",
              "logo": "https://www.glelectronics.net/imgs/GL-electronics-logo.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-480-468-9677",
                "contactType": "Customer Service"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "12"
              },
              "areaServed": [
                { "@type": "Place", "name": "Mesa" },
                { "@type": "Place", "name": "Phoenix" },
                { "@type": "Place", "name": "Tempe" },
                { "@type": "Place", "name": "Scottsdale" },
                { "@type": "Place", "name": "Chandler" },
                { "@type": "Place", "name": "Gilbert" },
                { "@type": "Place", "name": "Apache Junction" },
                { "@type": "Place", "name": "Glendale" }
              ],
              "sameAs": [
                "https://www.facebook.com/GLElectronics",
                "https://www.instagram.com/glelectronics18"
              ]
            }
          `}
        </script>
      </Helmet>

      {/* Header */}
      <header className="HeaderContainerH">
        <picture>
          <source
            srcSet="https://www.glelectronics.net/imgs/CITY-mobile.webp"
            media="(max-width: 850px)"
          />
          <source
            srcSet="https://www.glelectronics.net/imgs/CITY.webp"
            media="(min-width: 851px)"
          />
          <img
            src="https://www.glelectronics.net/imgs/CITY.webp"
            alt="City background for GL Electronics"
            className="HeaderImage"
            width="1920"
            height="500"
          />
        </picture>
        <div className="HeaderStyle">
          <h1 className="HeadingTitle">
            SECURE YOUR WORLD WITH ADVANCED SECURITY SOLUTIONS
          </h1>
        </div>
      </header>

      {/* Promo */}
      <Link style={{textDecoration: 'none'}} to="/Contact">
        <section className="PromoH">
          <div className="TitlePH">
            <div className="PromoContH" style={{marginTop: 4, fontSize: '1.4rem', textIndent: 25, fontWeight: 'bold'}}>PROMOTION:</div>
          </div>

            <div className="Wtext">
              <p>FREE panel with monitoring agreement!</p>
              <div className="lineBreakH"></div>
              <p >24/7 professional monitoring starting at $29.99!</p>
            </div>
            <div className="Mtext">
              <p>
                FREE panel with monitoring agreement!
              <br/>
                24/7 professional monitoring starting at $29.99!
              </p>
                <IoIosArrowForward style={{color: 'white'}} size={50} />
          
            </div>
        </section>
      </Link>

      {/* Main Body */}
      <main className="MainBodyH">
        {/* TextBody */}
        <article className="TextBodyH">
        <h2>Security Solutions For Your Business</h2>
            <div className="BStotalCont">
              <div className="faBuilding"><FaBuilding /></div>
              <div className="bService">
                <p>
                  GL Electronics specializes in <strong>advanced security solutions</strong> tailored to meet the unique challenges of commercial properties. From small businesses to large enterprises, we deliver scalable and reliable systems that protect your assets and ensure peace of mind.
                </p>

                <h3>Advanced Surveillance Cameras</h3>
                <p>
                  Our cutting-edge <strong>surveillance camera systems</strong> are designed to provide comprehensive coverage of your business premises. These solutions help deter crime and provide valuable evidence when needed.
                </p>
                <ul>
                  <li>High-definition cameras with full-color night vision</li>
                  <li>Wide-angle and adjustable varifocal lens options</li>
                  <li>Cloud or on-site video storage for secure access</li>
                  <li>Remote monitoring via mobile or desktop applications</li>
                </ul>

                <h3>Alarm Systems for Business Security</h3>
                <p>
                  Stay protected with our advanced <strong>alarm systems</strong>. From intrusion detection to panic alarms, our solutions ensure swift responses to any security breach.
                </p>
                <ul>
                  <li>Entry-point sensors and motion detectors</li>
                  <li>24/7 professional monitoring services</li>
                  <li>Integration with access control and video systems</li>
                </ul>

                <h3>Access Control and Employee Management</h3>
                <p>
                  Regulate access to sensitive areas with our modern <strong>access control systems</strong>. Whether you need keycard entry, or video intercoms, we help you manage and monitor who enters your property.
                </p>
                <ul>
                  <li>Keycard and fob systems</li>
                  <li>Time-tracking and employee access management</li>
                </ul>

                <h3>Networking and Communication Systems</h3>
                <p>
                  Enable seamless and secure connectivity across your business with our <strong>networking solutions</strong>. From structured wiring to secure VoIP setups, we ensure your communication systems are robust and reliable.
                </p>
                <ul>
                  <li>Structured cabling for efficient network organization</li>
                  <li>Secure <strong>VoIP installation</strong> and integration</li>
                  <li>Custom network solutions to suit your business scale</li>
                </ul>

                <h3>Custom-Tailored Solutions</h3>
                <p>
                  Every business has unique security needs, and we are committed to delivering personalized solutions that align with your requirements. Our team will assess your property and develop a security plan that maximizes protection and efficiency.
                </p>

                <p>
                  Schedule your <strong>FREE QUOTE TODAY!</strong> Our team will visit your property at no charge to create a customized plan that meets your needs.
                </p>
                
                <nav>
                  <Link to="../Contact" className="CButtonH">
                    Get Started <IoIosArrowForward style={{ color: 'white' }} />
                  </Link>
                </nav>

                <p>
                  <strong>ASK ABOUT OUR FINANCING OPTIONS!</strong> We offer flexible payment plans to help you implement the security solutions your business needs without delay.
                </p>
              </div>
            </div>

          
          <h2>Security Solutions For Your Home</h2>
<div className="HStotalcont">
  <div className="faHome"><FaHome /></div>
  <div className="hService">
    <p>
      At GL Electronics, we understand the importance of protecting your home and loved ones. That’s why we offer comprehensive <strong>home surveillance</strong> solutions tailored to meet your unique needs.
    </p>

    <h3>High-Quality Surveillance Cameras</h3>
    <p>
      Our <strong>residential surveillance cameras</strong> provide 24/7 monitoring, ensuring that every corner of your home is covered. Whether you’re at home or away, you can monitor your property remotely with our advanced mobile integration.
    </p>
        <ul>
          <li>High-definition video with full-color night vision</li>
          <li>Wide-angle fixed cameras and adjustable varifocal options</li>
          <li>Weatherproof designs for outdoor security</li>
          <li>Smart motion detection with instant alerts</li>
        </ul>

        <h3>Smart Alarm Systems</h3>
        <p>
          Protect your home with our reliable <strong>alarm systems</strong>. From entry-point sensors to glass break detectors, our alarms are designed to respond swiftly to unauthorized access. Pair this with our professional monitoring services for round-the-clock peace of mind.
        </p>

        <h3>Networking for Smart Homes</h3>
        <p>
          Enable seamless communication between your devices with our <strong>home networking solutions</strong>. From reliable Wi-Fi coverage to secure connections for your smart devices, we create a robust network to support your home automation and security systems.
        </p>

        <h3>Custom-Tailored Solutions</h3>
        <p>
          No two homes are alike, which is why we design custom solutions to address your specific security concerns. Our experts will assess your property and create a plan that ensures maximum coverage and efficiency.
        </p>

        <p>
          With GL Electronics, you can rest assured knowing your family and home are in safe hands. 
        </p>

        <nav>
          <Link to="../Contact" className="CButtonH">
            Lets Talk <IoIosArrowForward style={{ color: 'white' }} />
          </Link>
        </nav>
      </div>
    </div>

        </article>




        {/* IconBody */}
        <section className="IconBodyH">
          <div className="IconCardH">
            <div className="iconTextV">
              <TbDeviceLandlinePhone size={110}/>
              <span style={{textAlign: 'center'}}> VOICE OVER IP</span>
            </div>
            <div className="iconTextV">
              <p>
              Enhance your business communications with <strong>Voice Over IP (VoIP)</strong>. Our solutions provide seamless, cost-effective calling and advanced features, ensuring efficient and secure connectivity for your team.
              </p>
            </div>
          </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <IoKeypadSharp  size={110}/>
          <span style={{textAlign: 'center'}}>ACCESS CONTROL</span>
        </div>
        <div className="iconTextV">
            <p>
          Secure your property with our advanced <strong>Access Control</strong> systems. Manage and monitor entry points effortlessly, ensuring only authorized personnel have access to critical areas.
        </p>
        </div>

        </div>
      </section>

        <section className="IconBodyH">
          <div className="IconCardH">
            <div className="iconTextV">
              <TbDeviceCctv size={110}/>
              <span style={{textAlign: 'center'}}> CCTV Surveillance</span>
            </div>
            <div className="iconTextV">
              <p>
              Our <strong>CCTV surveillance</strong> systems help keep all types of occurances tracked. We also offer local 24/7 security monitoring to ensure the safety of your property.
              </p>
            </div>
          </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <IoIosGitNetwork size={110}/>
          <span style={{textAlign: 'center'}}>Networking Security Solutions</span>
        </div>
        <div className="iconTextV">
          <p>
            Our <strong>network security solutions</strong> enable seamless communication and resource sharing among devices. We also offer Structured Wiring. 
          </p>
        </div>

        </div>
      </section>

      <section className="IconBodyH">
        <div className="IconCardH">
          <div className="iconTextV">
            <MdOndemandVideo size={110}/>
            <span style={{textAlign: 'center'}}>Video Solutions</span>
          </div>
          <div className="iconTextV">
            <p>
                Our <strong>video solutions</strong> help customers monitor their property at the comfort of their home or on the go, ensuring safety and a peace of mind.
              </p>
          </div>
        </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <RiAlarmWarningLine size={110}/>
          <span style={{textAlign: 'center'}}>Alarm Systems</span>
        </div>
        <div className="iconTextV">
          <p>
          with our <strong>alarm systems</strong> we keep you protected with swift responses to security breaches using our local 24/7 security monitoring!
          </p>
        </div>
      </div>
    </section>

    {/* Insert partnership slide here */}

    <section className="tempbx">
      <div>
        <span>Temp text</span>
      </div>

    </section>

     

        {/* IMG Body */}
        <section className="IMGbodyH">
          <aside className="IMGrow" style={{marginRight: '15px'}}>
            <div style={{marginRight: '15px'}}>
            <LazyLoad>
                <div className="IMGcardVH0" style={{ '--background-image': `url(${Image4})` }} alt="White Security Camera"></div>
              </LazyLoad>
              <LazyLoad>
                <div className="IMGcardVH1" style={{ '--background-image': `url(${Image2})` }} alt="Close up of Camera Installation"></div>
              </LazyLoad>
            </div>
      
              <div className="IMGVertH">
              <LazyLoad>
                <div className="IMGcardVH0" style={{ '--background-image': `url(${Image3})` }} alt="Surveillance Camera Setup In Arizona"></div>
              </LazyLoad>
              </div>
        
          </aside>

          <article className="VertBox">
            <div className="infoBoxH">
              <h2>About Us</h2>
              <p style={{fontSize: '1.2rem'}}>
                GL Electronics is a locally owned and operated low voltage specialist servicing the major Phoenix metropolitan area since 2017. We've served homes and businesses, ranging from <strong>Mesa, Tempe, Chandler, Phoenix, Gilbert, Scottsdale, Glendale</strong> and many more! Our knowledgeable team members are trained to offer custom tailored solutions to fit every client's needs. We specialize in intuitive and advanced security solutions and other electronic technologies at competitive prices. 
                <br/>
                <br/>
                Look at some of our reviews below!
              </p>
            </div>

            <Slideshow />
            
          </article>
        </section>

        <nav>
          <Link to="../Contact" className="CButtonH">
          Get Started  <IoIosArrowForward style={{color: 'white'}} />
          </Link>
        </nav>
      </main>
    </>
  )
}

export default Home;