//BlogPage.js

import React from "react";


function BlogPage(){
    return(
        <div>

        </div>
    )
}

export default BlogPage;