import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import { Analytics } from "@vercel/analytics/react";
import Contact from './Pages/Contact.js';
import Home from './Pages/Home.js'; 
import Residential from './Pages/Residential.js'; 
import Commercial from './Pages/Commercial.js'; 
import BlogPage from "./Pages/BlogPage.js";
import ServiceRequest from "./Pages/ServiceRequest.js";
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  return (
    <Router>
      <div>
        <Header />
        <SpeedInsights />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/home-alarm-and-camera-security-systems" element={<Residential />} />
            <Route path="/voiceoverip-security-and-communication-systems" element={<Commercial />} />
            <Route path="/service-request" element={<ServiceRequest />} />
            <Route path="/blogpage" element={<BlogPage />} />
          </Routes>
    
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
