import React from "react";
import '../Styles/Com_Style.css';
import { FaPhoneAlt } from "react-icons/fa";

function CallNumCom() {
  const phoneNumber = '(480)468-9677';
  
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div onClick={handleCallClick} style={{ cursor: 'pointer' }}>
      <span className="newCallBtn">   Financing available, Call us to learn more!  <FaPhoneAlt size={30} style={{ marginLeft: 10 }} /></span>
    </div>
  );
}

export default CallNumCom;
