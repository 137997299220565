//MenuDrop.js

import React from "react";
import { Link } from "react-router-dom";
import '../Styles/HeaderStyle.css';

function MenuBar({ closeMenu, isMenuOpen }) {
  return (
    <nav className={`menuBox ${isMenuOpen ? 'menuBoxOpen' : 'menuBoxClosed'}`}>
      <div className="menuBoxStyle">
        <Link to="../home-alarm-and-camera-security-systems" style={{ textDecoration: 'none', color: 'white', fontSize: '1.3rem', paddingRight: '15px' }} onClick={closeMenu}>
          Residential
        </Link>
      </div>
      <div className="menuBoxStyle">
        <Link to="../voiceoverip-security-and-communication-systems" style={{ textDecoration: 'none', color: 'white', fontSize: '1.3rem', paddingRight: '15px' }} onClick={closeMenu}>
          Commercial
        </Link>
      </div>
      <div className="menuBoxStyle">
        <Link to="../service-request" style={{ textDecoration: 'none', color: 'white', fontSize: '1.3rem', paddingRight: '15px' }} onClick={closeMenu}>
          Service Request
        </Link>
      </div>
      <div className="menuBoxStyle">
        <Link to="../contact" style={{ textDecoration: 'none', color: 'white', fontSize: '1.3rem', paddingRight: '15px' }} onClick={closeMenu}>
          Contact
        </Link>
      </div>
    </nav>
  );
}

export default MenuBar;
