// Contact.js

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Form from "../Components/Form";
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosPhonePortrait } from "react-icons/io";
import "../index.css";
import "../Styles/Contact.css";
import { InlineWidget } from "react-calendly";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);

  //   // Add the class to the body for the contact page
  //   document.body.classList.add("contact-background");

  //   // Cleanup function to remove the class when unmounting
  //   return () => {
  //     document.body.classList.remove("contact-background");
  //   };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact Us - GL Electronics</title>
        <link rel="canonical" href="https://www.glelectronics.net/contact" />
        <meta 
          name="description" 
          content="Contact GL Electronics for expert security solutions in Arizona. Schedule a consultation or reach out for any inquiries" 
        />
      </Helmet>

      <div className="App">
        <InlineWidget url="https://calendly.com/glelectronics/free-consultation" />
      </div>

      <div className="contentBox">
        <div className="containerStyle">
          <div className="formHeader">
            <h1 className="headingStyle" style={{ fontSize: '1.8rem' }}>Have Questions?</h1>
            <p>Send us an Email.</p>
          </div>
          <Form />
        </div>

        <div className="IBC">
          <FaPhoneAlt size={30} style={{ marginRight: 15 }} />
          <IoIosPhonePortrait size={30} style={{ marginRight: 15 }} />
          <AiOutlineMail size={30} style={{ marginRight: 15 }} />
          <br />
          <span>
            <br />
            CALL or Text US at
            <br />
            (480) 468 9677
            <br />
            <br />
            Or Email us if you have other inquiries:
            <br />
            geoff@glelectronics.net
          </span>

          <span>
            <br/>
            Surveillance
            <br/>
            Access Control
            <br/>
            Networking
            <br/>
            Audio / Video
            <br/>
            Alarm / Systems
            <br/>
            Structured Wiring
            <br/>
            Voice Over IP
            <br/>
            Intercom Systems
            <br/>
          </span>

          <div>
            <p>
              Forms of payment that we accept:
              <ul style={{ listStyle: 'none' }}>
                <li>Check</li>
                <li>Cash</li>
                <li>Credit/Debit</li>
                <li>Zelle</li>
              </ul>
              Areas that we serve: Mesa, Tempe, Phoenix, Chandler, Gilbert, Scottsdale, Glendale, and many more! 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  // document.body.classList.remove("contact-background");
}

export default Contact;
