//Header.js
import { Link, useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import CallNum from './CallNum';
import React, { useState } from 'react';
import { IoMenu, IoClose } from "react-icons/io5";
import '../Styles/HeaderStyle.css';
import MenuDrop from './MenuDrop';

const glElectronicsLogo = "https://www.glelectronics.net/imgs/GL-electronics-logo.png";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Get the current route

  const iconSize = 40;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const mIcon = isMenuOpen ? "menuIconOpen" : "menuIconClosed";

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // Conditional style for header background
  const headerStyle = {
    backgroundColor: location.pathname === '/contact' || location.pathname === '/service-request' 
      ? 'rgb(58, 58, 58)' 
      : 'transparent',
  };

  return (
    <header>
      <div style={headerStyle} className="header-d">
        <Link to="/" onClick={scrollToTop}>
          <img src={glElectronicsLogo} alt="GL Electronics Logo" className='logoStyle' />
        </Link>

        <nav>
          <Navigation />
        </nav>

        <div>
          <CallNum />
        </div>
      </div>

      {/* Header for Mobile */}
      <div style={headerStyle} className='header-m'>
        <Link
          to="/"
          onClick={() => {
            scrollToTop();
            if (isMenuOpen) toggleMenu();
          }}
        >
          <img src={glElectronicsLogo} alt="GL Electronics Logo" className='logoStyle' />
        </Link>

        <div className='h-box1'>
          <div>
            <CallNum />
          </div>

          {isMenuOpen ? (
            <IoClose className={mIcon} size={iconSize} onClick={toggleMenu} style={{ cursor: 'pointer' }} />
          ) : (
            <IoMenu className={mIcon} size={iconSize} onClick={toggleMenu} style={{ cursor: 'pointer' }} />
          )}
        </div>
      </div>

      <MenuDrop closeMenu={toggleMenu} isMenuOpen={isMenuOpen} />
    </header>
  );
}

export default Header;
