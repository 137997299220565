import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import { IoIosArrowForward } from "react-icons/io";
import "../Styles/Res_Style.css";
import "../index.css";


const Image2 = "https://www.glelectronics.net/imgs/ResImg/Res_1.webp";
const Image3 = "https://www.glelectronics.net/imgs/ResImg/Net_1.webp";

function Residential() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      <Helmet>
  <title>Residential Security Solutions - GL Electronics</title>
  <link rel="canonical" href="https://www.glelectronics.net/home-alarm-and-camera-security-systems" />

  {/* Primary Meta Description for SEO */}
  <meta
    name="description"
    content="Explore GL Electronics' residential security solutions, including home surveillance installation, Monitoring, and advanced networking. Protect your home!"
  />

  {/* Open Graph for Social Sharing */}
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Residential Security Solutions - GL Electronics" />
  <meta
    property="og:description"
    content="Explore GL Electronics' residential security solutions, including home surveillance installation, Monitoring, and advanced networking. Protect your home!"
  />
  <meta property="og:image" content="https://www.glelectronics.net/imgs/ResImg/Res_1.webp" />
  <meta property="og:image:width" content="800" />
  <meta property="og:image:height" content="600" />
  <meta property="og:url" content="https://www.glelectronics.net/home-alarm-and-camera-security-systems" />

  {/* Schema Markup */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Residential Security Solutions - GL Electronics",
        "description": "Explore GL Electronics' residential security solutions, including home surveillance installation, CCTV camera setup, and advanced networking to protect your home across Arizona.",
        "url": "https://www.glelectronics.net/home-alarm-and-camera-security-systems",
        "image": {
          "@type": "ImageObject",
          "url": "https://www.glelectronics.net/imgs/ResImg/Res_1.webp",
          "width": 800,
          "height": 600
        },
        "publisher": {
          "@type": "Organization",
          "name": "GL Electronics",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.glelectronics.net/imgs/GL-electronics-logo.png",
            "width": 192,
            "height": 192
          }
        },
        "mainEntity": {
          "@type": "Service",
          "name": "Residential Security Solutions",
          "description": "Comprehensive residential security solutions including home surveillance, networking, and alarm systems tailored to meet your needs.",
          "areaServed": [
            { "@type": "Place", "name": "Mesa" },
            { "@type": "Place", "name": "Phoenix" },
            { "@type": "Place", "name": "Tempe" },
            { "@type": "Place", "name": "Scottsdale" },
            { "@type": "Place", "name": "Chandler" },
            { "@type": "Place", "name": "Gilbert" },
            { "@type": "Place", "name": "Apache Junction" },
            { "@type": "Place", "name": "Glendale" }
          ]
        }
      }
    `}
  </script>
</Helmet>

      {/* Header */}

      <header className="headerR">
      <img
        src="https://www.glelectronics.net/imgs/ResImg/Interior1.webp"
        alt="house interior for GL Electronics"
        className="HeaderImageR"
        width="1920"
        height="500"
      />
        <div className="headingStyleR">
          <h1 className="headerTitleR">Residential Security Solutions</h1>
        </div>
      </header>

      {/* Introduction */}
      <section>
        <div className="infoBoxTR">
          <p>
            Throughout the Valley, we offer a comprehensive range of <strong>home surveillance installation</strong> solutions:
          </p>
        <p>
            including high-definition <strong>security camera systems</strong>, motion detection sensors, and smart integration features.
        </p>
        <p>
           Our <strong>CCTV installation</strong> and advanced security systems ensure that your home is protected with the latest in <strong>security technology</strong>.
        </p>
        <p>
          Want to take it a step further? We also offer <strong>24/7 local monitoring</strong>.
        </p>
          <Link to="../Contact" className="infoBoxBtn" style={{ top: 50 }}>
            Schedule Now! <IoIosArrowForward style={{ color: 'white' }} size={20} />
          </Link>
        </div>
      </section>

      {/* Surveillance */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center' }}>
            <LazyLoad>
              <div className="IMGholderR" style={{ '--background-image': `url(${Image2})` }} alt="Residential home surveillance system"></div>
            </LazyLoad>
            <div className="infoBoxR infoBoxCamR">
              <h2>Home Surveillance Installation</h2>
              <p>
                Our <strong>home surveillance</strong> services offer peace of mind by providing round-the-clock monitoring of your property either at the comfort of your home or where ever you are.
              </p>                 
                <p>
                 Ensuring the safety and security of your home. Our <strong>CCTV camera installation</strong> is designed to deliver high-quality video and reliable performance.
                </p>
              <ul style={{ listStyle: 'none' }}>
                <li>24/7 FULL COLOR</li>
                <li>5MP & 8MP FIXED WIDE ANGLE</li>
                <li>5MP & 8MP VARIFOCAL</li>
              </ul>
              <p>
                To learn more about the <strong>CCTV systems</strong> and technologies we offer, feel free to check out the equipment that we use:
                <br />
                <a style={{ textDecoration: 'none', color: 'blue' }} href="https://www.cctvcore.com" target="_blank" rel="noopener noreferrer">www.cctvcore.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Networking */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center', paddingBottom: '50px' }}>
            <div className="infoBoxR infoBoxNetR">
              <h2>Networking Security Solutions for Homes</h2>
              <p>
                Our home <strong>networking service</strong> enhances connectivity within your household, enabling seamless communication and sharing of resources among devices. 
              </p>
              <p>
                With our expertise in home networking, we create custom <strong>security solutions</strong> that prioritize security, efficiency, and scalability to support and secure your home.
              </p>
            </div>
            <LazyLoad>
              <div className="IMGholderR" style={{ '--background-image': `url(${Image3})` }} alt="Home networking setup"></div>
            </LazyLoad>
          </div>
        </div>
      </section>

      {/* Other Services */}
      <section>
        <div className="bottomContainterR">
          <div className="infoBoxRend">
            <h2>Other Residential Services</h2>
            <p>
              In addition to <strong>CCTV installation</strong>, we also specialize in home alarm systems. We provide advanced security measures to manage and monitor entry points, ensuring only authorized individuals can access your home, adding an extra layer of safety for your family and property.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Link */}
      <nav>
        <Link to="../Contact" className="CButtonR" style={{ top: 50 }}>
          Ready to Secure your Home <IoIosArrowForward style={{ color: 'white' }} size={20} />
        </Link>
      </nav>
    </>
  );
}

export default Residential;