import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import { useSpring, animated } from "@react-spring/web";

import '../Styles/HeaderStyle.css';

function Navigation() {

  
  return (
    <animated.div>
      <nav>
      <Link
        to="/home-alarm-and-camera-security-systems"
        className="contactStyle"
        style={{ textDecoration: 'none', borderRadius: 15 }}
      >
        Residential
      </Link>
      <Link
        to="/voiceoverip-security-and-communication-systems"
        className="contactStyle"
        style={{ textDecoration: 'none', borderRadius: 15 }}
      >
        Commercial
      </Link>
      <Link
        to="/service-request"
        className="contactStyle"
        style={{ textDecoration: 'none', borderRadius: 15 }}
      >
        Service Request
      </Link>
      <Link
        to="/contact"
        className="contactStyle"
        style={{ textDecoration: 'none', borderRadius: 15 }}
      >
        Contact
      </Link>
      </nav>
    </animated.div>
  );
}

export default Navigation;
