//Form.js
import React, { useState } from 'react';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const container1 = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '300px',
    height: '350px',
    // Other styles as needed
    // backgroundColor: 'purple',
    justifyContent: 'center',
  };

  /***************************---button---************************************/

  const buttonStyle = {
    height: '30px',
    borderRadius: '15px',
    fontSize: '1.4rem',
    backgroundColor: 'maroon',
    color: 'white',
    transition: 'transform 0.2s ease-in-out', // Add transition for smoother animation
  };

  // Input styles
  const inputStyle = {
    width: '80%', // Adjust the width as needed
    height: '40px', // Adjust the height as needed
    borderRadius: '8px', // Adjust the border radius as needed
    //padding: '8px', // Adjust the padding as needed
    marginLeft: 25,
  };

  return (
    <div>
      <form action="https://formspree.io/f/xdoqpoqd" method="POST" style={container1}>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            style={inputStyle} // Apply input styles
            required
          />
        </div>

        <div>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            style={inputStyle} // Apply input styles
            required
          />
        </div>

        <div>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            style={inputStyle} // Apply input styles
            required
          />
        </div>

        <div>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            placeholder="Company"
            style={inputStyle} // Apply input styles
          />
          {/* No error message for company */}
        </div>

        <div>
          <textarea
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            placeholder="How can we get started."
            rows="4"
            style={{ ...inputStyle, resize: 'vertical' }} // Apply input styles for textarea
            required
          ></textarea>
        </div>

        {/* Submit Button */}
        <button style={buttonStyle} type="submit">
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default Form;